import Vue from 'vue';
import Router from 'vue-router';
import { Role } from '@/core/user/roles.js';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/reisetermine/overview',
      component: () => import('@/view/layout/Layout'),
      children: [
        {
          path: '/einstellungen',
          name: 'einstellungen',
          component: () => import('@/view/pages/einstellungen/einstellungen.vue'),
          meta: { title: 'Einstellungen' },
          children: [
            {
              path: 'testdata',
              name: 'einstellungen-test-data',
              component: () => import('@/view/pages/einstellungen/einstellungen-test-data.vue'),
              props: true,
              meta: { authorize: [Role.ADMIN] },
            },
            {
              path: 'simulate',
              name: 'einstellungen-simulate',
              component: () => import('@/view/pages/einstellungen/einstellungen-simulate.vue'),
              props: true,
              meta: { authorize: [Role.ADMIN] },
            },
            {
              path: 'user',
              name: 'einstellungen-user',
              component: () => import('@/view/pages/einstellungen/einstellungen-user.vue'),
              props: true,
              meta: { authorize: [Role.ADMIN] },
            },
          ],
        },
        {
          path: '/umfragen',
          name: 'umfragen',
          component: () => import('@/view/pages/umfragen/umfragen.vue'),
          meta: { authorize: [Role.ADMIN] },
        },
        {
          path: '/aufgaben',
          name: 'aufgaben',
          component: () => import('@/view/pages/aufgaben/aufgaben.vue'),
          meta: { authorize: [Role.ADMIN, Role.PM, Role.YIELD, Role.AM, Role.DOM, Role.FOM] },
          children: [
            {
              path: 'overview',
              name: 'aufgaben-overview',
              component: () => import('@/view/pages/aufgaben/aufgaben-overview.vue'),
              meta: {
                authorize: [Role.ADMIN, Role.PM, Role.YIELD, Role.AM, Role.DOM, Role.FOM],
                title: 'Aufgabenübersicht',
              },
            },
          ],
        },
        {
          path: '/yield',
          name: 'yield',
          component: () => import('@/view/pages/teams/yield/yield.vue'),
          meta: { authorize: [Role.ADMIN, Role.YIELD] },
          children: [],
        },
        {
          path: '/wbscgi',
          name: 'csv-query',
          component: () => import('@/view/pages/blank/flugverfuegbarkeits-query.vue'),
          meta: { title: 'Flugverfügbarkeit' },
        },
        {
          path: '/reisen',
          name: 'reisen',
          component: () => import('@/view/pages/reisen/reisen.vue'),
          children: [
            {
              path: 'prioritaeten',
              name: 'reisen-prioritaeten',
              component: () => import('@/view/pages/teams/Sales/prioritaeten-view.vue'),
              meta: { title: 'Prioritäten' },
            },
            {
              path: 'overview',
              name: 'reisen-overview',
              component: () => import('@/view/pages/reisen/reisen-overview.vue'),
              meta: { title: 'Reiseübersicht' },
            },
            {
              path: ':reisekuerzel',
              component: () => import('@/view/pages/reisen/reisen-view.vue'),
              props: true,
              children: [
                {
                  path: 'edit',
                  name: 'reisen-edit-view',
                  component: () => import('@/view/pages/reisen/reisen-edit-view.vue'),
                  meta: { title: 'Reisen bearbeiten' },
                  props: true,
                },
                {
                  path: 'reisestammdaten',
                  name: 'reisen-reisestammdaten-view',
                  component: () => import('@/view/pages/reisen/reisen-metadata-view.vue'),
                  meta: { title: 'Reisestammdaten bearbeiten' },
                  props: true,
                },
                {
                  path: 'th-vk-max',
                  name: 'reisen-saisonpreise-view',
                  component: () => import('@/view/pages/reisen/reisen-saisonpreise-view.vue'),
                  meta: { title: 'TH / VK Max bearbeiten' },
                  props: true,
                },
                {
                  path: 'passdatenkonfiguration',
                  name: 'reisen-edit-passdatenkonfiguration-view',
                  component: () => import('@/view/pages/reisen/reisen-edit-passdatenkonfiguration-view.vue'),
                  meta: { title: 'Reiseterminpassdaten-Konfiguration' },
                  props: true,
                },
              ],
            },
          ],
        },
        {
          path: '/reisetermine',
          name: 'reisetermine',
          component: () => import('@/view/pages/reisetermine/reisetermine.vue'),
          children: [
            {
              path: 'garantiert',
              name: 'fom-garantierte-reisetermine',
              component: () => import('@/view/pages/teams/FOM/garantierte-reisetermine-view.vue'),
              meta: { title: 'Garantierte Reisetermine' },
            },
            {
              path: 'fvc/overview',
              name: 'yield-overview',
              component: () => import('@/view/pages/teams/yield/yield-overview.vue'),
              meta: {
                title: 'FVC-Übersicht',
                authorize: [Role.ADMIN, Role.YIELD],
              },
            },
            {
              path: 'fvc/rm-reisebasis',
              component: () => import('@/view/pages/teams/yield/rm-reisebasis.vue'),
              meta: {
                title: 'RM Reisebasis',
                authorize: [Role.ADMIN, Role.YIELD],
              },
            },
            {
              path: 'fvc/rm-historie',
              component: () => import('@/view/pages/teams/yield/rm-historie.vue'),
              meta: {
                title: 'RM Historie',
                authorize: [Role.ADMIN, Role.YIELD],
              },
            },
            {
              path: 'fvc/kpi-dashboard',
              component: () => import('@/view/pages/teams/yield/kpi-dashboard.vue'),
              meta: {
                title: 'KPI Dashboard',
                authorize: [Role.ADMIN, Role.YIELD],
              },
            },
            {
              path: 'fvc/kpi-leading',
              component: () => import('@/view/pages/teams/yield/leading-kpis.vue'),
              meta: {
                title: 'Leading KPIs',
                authorize: [Role.ADMIN, Role.YIELD],
              },
            },
            {
              path: 'fvc/kpi-historie',
              component: () => import('@/view/pages/teams/yield/kpi-historie.vue'),
              meta: {
                title: 'KPI Historie',
                authorize: [Role.ADMIN, Role.YIELD],
              },
            },
            {
              path: 'releaseprozess',
              name: 'dom-releaseprozess',
              component: () => import('@/view/pages/teams/DOM/releaseprozess-reisetermine-view.vue'),
              meta: { title: 'Releaseprozesse' },
            },
            {
              path: 'zvc',
              name: 'zvc',
              component: () => import('@/view/pages/reisetermine/zvc-view.vue'),
              meta: {
                title: 'Zimmerverfügbarkeitscontrolling',
                authorize: [Role.ADMIN, Role.PM, Role.YIELD, Role.DOM],
              },
            },
            {
              path: 'reisefinder',
              name: 'reisetermin-reisefinder',
              component: () => import('@/view/pages/teams/Sales/reisefinder-view.vue'),
              meta: { title: 'Reisefinder' },
            },
            {
              path: 'planung',
              name: 'pm-planung',
              component: () => import('@/view/pages/teams/PM/terminplanung-view.vue'),
              meta: { title: 'Reiseterminplanung' },
            },
            {
              path: 'flugplanung',
              name: 'flugplanung-planung',
              component: () => import('@/view/pages/reisetermine/flugplanung-overview.vue'),
              meta: { title: 'Flugplanung' },
            },
            {
              path: 'overview',
              name: 'reisetermine-overview',
              component: () => import('@/view/pages/reisetermine/reisetermine-overview.vue'),
              meta: { title: 'Reiseterminübersicht' },
            },
            {
              path: ':reiseterminId',
              component: () => import('@/view/pages/reisetermine/reisetermin-view.vue'),
              props(route) {
                const props = { ...route.params };
                props.reiseterminId = parseInt(props.reiseterminId);
                return props;
              },
              children: [
                {
                  path: '',
                  name: 'reisetermine-view',
                  component: () => import('@/view/pages/reisetermine/reisetermin-view-tab-default.vue'),
                  meta: { title: 'Reisetermindetails' },
                },
                {
                  path: 'releasepruefung',
                  name: 'reisetermin-view-releasepruefung',
                  component: () =>
                    import('@/view/pages/reisetermine/reisetermin-view-tab-releasepruefung.vue'),
                  meta: { title: 'Reisetermin-Releaseprüfung' },
                },
                {
                  path: 'einstellungen',
                  name: 'reisetermin-view-einstellungen',
                  component: () => import('@/view/pages/reisetermine/reisetermin-view-tab-einstellungen.vue'),
                  meta: { title: 'Reisetermin-Einstellungen' },
                },
                {
                  path: 'history',
                  name: 'reisetermine-view-history',
                  component: () => import('@/view/pages/reisetermine/reisetermin-view-tab-history.vue'),
                  meta: { title: 'Reisetermin-History' },
                },
              ],
            },
          ],
        },
        {
          path: '/OM',
          name: 'OM',
          component: () => import('@/view/pages/teams/OM/om-view.vue'),
          meta: { title: 'Office Management' },
          children: [
            {
              path: '/kunden',
              name: 'kunden',
              component: () => import('@/view/pages/kunden/kunden.vue'),
              children: [
                {
                  path: 'overview',
                  name: 'kunden-overview',
                  component: () => import('@/view/pages/kunden/kunden-master-overview.vue'),
                  props: true,
                  meta: { authorize: [Role.ADMIN, Role.OM], title: 'Kunden-Übersicht' },
                },
                {
                  path: 'cleaning',
                  name: 'dubletten-cleaning',
                  component: () => import('@/view/pages/kunden/kunden-dubletten-cleaning-view.vue'),
                  meta: { authorize: [Role.ADMIN, Role.OM], title: 'Kunden-Bereinigung' },
                },
                {
                  path: 'confirmation',
                  name: 'dubletten-confirmation',
                  component: () => import('@/view/pages/kunden/kunden-dubletten-confirmation-view.vue'),
                  meta: { authorize: [Role.ADMIN, Role.OM], title: 'Kunden-Dublettenprüfung' },
                },
                {
                  path: ':kundenId',
                  component: () => import('@/view/pages/kunden/kunden-view.vue'),
                  props(route) {
                    const props = { ...route.params };
                    props.kundenId = parseInt(props.kundenId);
                    return props;
                  },
                  children: [
                    {
                      name: 'kunden-view',
                      path: '',
                      component: () => import('@/view/pages/kunden/kunden-view-tab-default.vue'),
                      meta: { title: 'Kunden Details' },
                    },
                  ],
                },
              ],
            },
            {
              path: '/agenturen',
              name: 'agenturen',
              component: () => import('@/view/pages/agenturen/agenturen.vue'),
              children: [
                {
                  path: 'overview',
                  name: 'agenturen-overview',
                  component: () => import('@/view/pages/agenturen/agenturen-overview.vue'),
                  props: true,
                  meta: { authorize: [Role.ADMIN, Role.OM], title: 'Agenturen-Übersicht' },
                },
                {
                  path: 'confirmation',
                  name: 'agenturen-dubletten-confirmation',
                  component: () => import('@/view/pages/agenturen/agenturen-dubletten-confirmation-view.vue'),
                  meta: {
                    authorize: [Role.ADMIN, Role.OM],
                    title: 'Agenturen-Dublettenprüfung',
                  },
                },
              ],
            },
          ],
        },
        {
          path: '/vorgaenge',
          name: 'vorgaenge',
          component: () => import('@/view/pages/vorgaenge/vorgaenge.vue'),
          title: 'Agenturen / Dubletten Bestägtigung',
          meta: { authorize: [Role.ADMIN] },
          children: [
            {
              path: ':vorgangId',
              name: 'vorgaenge-view',
              component: () => import('@/view/pages/vorgaenge/vorgaenge-view.vue'),
              meta: { authorize: [Role.ADMIN], title: 'Vorgänge / Details' },
              props(route) {
                const props = { ...route.params };
                props.vorgangId = parseInt(props.vorgangId);
                return props;
              },
            },
          ],
        },
      ],
    },
    {
      path: '/code=*',
      name: 'code',
      redirect: { path: '/reisetermine/overview' },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/view/pages/auth/auth.vue'),
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      props: true,
      component: () => import('@/view/pages/auth/not-authorized.vue'),
    },
    {
      path: '/restricted',
      name: 'restricted',
      meta: {
        authorize: ['nobody'],
      },
      component: () => import('@/view/pages/auth/not-authorized.vue'),
    },
    {
      path: '*',
      redirect: '/404',
    },
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      component: () => import('@/view/pages/error/Error-1.vue'),
      meta: { title: 'SKR Manager | Fehler :(' },
    },
    {
      path: '/403',
      name: '403',
      component: () => import('@/view/pages/error/Error-403.vue'),
      meta: { title: 'SKR Manager | Fehler :(' },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // on popstate events (forwards, backwards) jump to previous position
      return savedPosition;
    } else if (to.hash) {
      // if hash is set get the corresponding element and try to scroll to it
      const element = document.querySelector(to.hash);
      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth',
        });
      } else {
        // element not found -> return selector
        return { selector: to.hash };
      }
    } else {
      //no hash or history found scroll to top
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  },
});
