module.exports = {
  abreisedatum: {
    key: 'abreisedatum',
    label: 'Abreisedatum',
    expand: null,
    formatter: 'dateFilter',
    category: 'Datum',
  },
  bearbeiter: {
    key: 'bearbeiter',
    label: 'Bearbeiter',
    expand: null,
    formatter: 'username',
    category: 'Sonstige',
  },
  besonderheiten: {
    key: 'besonderheiten',
    label: 'Besonderheiten',
    expand: null,
    formatter: 'stripHtml',
    category: 'Sonstige',
  },
  createdDateUtc: {
    key: 'createdDateUtc',
    label: 'Erstelldatum',
    expand: null,
    formatter: 'dateFilter',
    category: 'Sonstige',
  },
  state: {
    key: 'state',
    label: 'Status',
    formatter: null,
    category: 'Status',
  },
  stateType: {
    key: 'stateType',
    label: 'Statustype',
    formatter: null,
    category: 'Status',
  },
  id: {
    key: 'id',
    label: 'ID',
    expand: null,
    formatter: null,
    category: 'Bezeichner',
  },
  kalkPax: {
    key: 'metadata.kalkPax',
    label: 'Kalk PAX',
    expand: { metadata: {} },
    formatter: null,
    category: 'PAX',
  },
  maxPax: {
    key: 'pax.max',
    label: 'Max PAX',
    expand: { pax: {} },
    formatter: null,
    category: 'PAX',
  },
  minPax: {
    key: 'pax.min',
    label: 'Min PAX',
    expand: { pax: {} },
    formatter: null,
    category: 'PAX',
  },
  reiseId: {
    key: 'reiseId',
    label: 'Reise ID',
    expand: null,
    formatter: null,
    category: 'Bezeichner',
  },
  reisedauer: {
    key: 'metadata.reisedauer',
    label: 'Reise Dauer',
    expand: null,
    formatter: null,
    category: 'Number',
  },
  reiseterminkuerzel: {
    key: 'reiseterminkuerzel',
    label: 'Reiseterminkürzel',
    expand: null,
    formatter: null,
    category: 'Bezeichner',
  },
  releasedatum: {
    key: 'releasedatum',
    label: 'Releasedatum',
    expand: null,
    formatter: 'dateFilter',
    category: 'Datum',
  },
  startdatum: {
    key: 'startdatum',
    label: 'Startdatum (H)',
    expand: null,
    formatter: 'dateFilter',
    category: 'Datum',
  },
  umsatz: {
    key: 'umsatz',
    class: 'position-sticky cw-100',
    tdClass: 'text-right',
    label: 'Umsatz',
    expand: null,
    formatter: 'currency',
    category: 'Sonstige',
  },
  updatedDateUtc: {
    key: 'updatedDateUtc',
    label: 'Geupdated',
    expand: null,
    formatter: 'dateFilter',
    category: 'Datum',
  },
  reiseterminSyncState: {
    key: 'reiseterminSyncState',
    label: 'Reisetermin SyncState',
    expand: null,
    formatter: null,
    category: 'Flags',
  },
  isDeleted: {
    key: 'isDeleted',
    label: 'Gelöscht',
    expand: null,
    formatter: 'jaNein',
    category: 'Flags',
  },
  baseState: {
    key: 'reiseterminState.baseState',
    label: 'Base State',
    formatter: null,
    category: 'Status',
  },
  workflowState: {
    key: 'reiseterminState.workflowState',
    label: 'Workflow State',
    formatter: null,
    category: 'Status',
  },
  istPax: {
    key: 'pax.ist',
    label: 'Ist PAX',
    expand: { pax: {} },
    formatter: null,
    category: 'PAX',
  },
  optPax: {
    key: 'pax.opt',
    label: 'Opt PAX',
    expand: { pax: {} },
    formatter: null,
    category: 'PAX',
  },
  abgesagt: {
    key: 'abgesagt',
    label: 'Abgesagt',
    formatter: 'jaNein',
    category: 'reiseterminBlankData',
  },
  garantiert: {
    key: 'garantiert',
    label: 'Garantiert',
    class: 'cw-80 text-center',
    formatter: 'jaNein',
    category: 'reiseterminBlankData',
  },
  stopSale: {
    key: 'stopSale',
    label: 'Stop Sale',
    class: 'cw-80 text-center',
    formatter: null,
    category: 'reiseterminBlankData',
  },
  kalkulierterFlugEk: {
    key: 'kalkulierterFlugEk',
    label: 'Kalkulierter FlugEk',
    formatter: 'currency',
    category: 'reiseterminBlankData',
  },
  blankImportedDateUtc: {
    key: 'blankImportedDateUtc',
    label: 'BlankImportedDateUtc',
    formatter: 'dateFilter',
    category: 'reiseterminBlankData',
  },
  coreImportedDateUtc: {
    key: 'coreImportedDateUtc',
    label: 'CoreImportedDateUtc',
    formatter: 'dateFilter',
    category: 'reiseterminBlankData',
  },
  frist: {
    key: 'reiseterminWorkflowFrist.frist',
    label: 'Frist',
    expand: { reiseterminWorkflowFrist: {} },
    formatter: null,
    category: 'reiseterminWorkflowFrist',
  },
  fristDatum: {
    key: 'reiseterminWorkflowFrist.fristDatum',
    label: 'Frist Datum',
    expand: { reiseterminWorkflowFrist: {} },
    formatter: 'dateFilter',
    category: 'reiseterminWorkflowFrist',
  },
  dmcHotels: {
    key: 'reiseterminReleaseprozess.dmcHotels',
    label: 'DMC Hotels',
    expand: { reiseterminReleaseprozess: {} },
    formatter: 'jaNein',
    category: 'reiseterminReleaseprozess',
  },
  dmcReiseleiter: {
    key: 'reiseterminReleaseprozess.dmcReiseleiter',
    label: 'DMC Reiseleiter',
    expand: { reiseterminReleaseprozess: {} },
    formatter: 'jaNein',
    category: 'reiseterminReleaseprozess',
  },
  dmcTransfers: {
    key: 'reiseterminReleaseprozess.dmcTransfers',
    label: 'DMC Transfers',
    expand: { reiseterminReleaseprozess: {} },
    formatter: 'jaNein',
    category: 'reiseterminReleaseprozess',
  },
  dmcReiseablauf: {
    key: 'reiseterminReleaseprozess.dmcReiseablauf',
    label: 'DMC Reiseablauf',
    expand: { reiseterminReleaseprozess: {} },
    formatter: 'jaNein',
    category: 'reiseterminReleaseprozess',
  },
  vertraglichVereinbarteQualitaetGeprueft: {
    key: 'reiseterminReleaseprozess.vertraglichVereinbarteQualitaetGeprueft',
    label: 'Vertraglich vereinbarte Qualitaet geprüft',
    expand: { reiseterminReleaseprozess: {} },
    formatter: 'jaNein',
    category: 'reiseterminReleaseprozess',
  },
  reiseunterlagenGeprueft: {
    key: 'reiseterminReleaseprozess.reiseunterlagenGeprueft',
    label: 'reiseunterlagenGeprueft',
    expand: { reiseterminReleaseprozess: {} },
    formatter: 'jaNein',
    category: 'reiseterminReleaseprozess',
  },
  grundDurchfuehrung: {
    key: 'reiseterminReleaseprozess.grundDurchfuehrung',
    label: 'Grund Durchführung',
    expand: { reiseterminReleaseprozess: {} },
    formatter: null,
    category: 'reiseterminReleaseprozess',
  },
  reisekuerzel: {
    key: 'reise.reisekuerzel',
    label: 'reisekuerzel',
    expand: { reise: {} },
    formatter: null,
    category: 'Reise',
  },
  country: {
    key: 'reise.country',
    label: 'Land',
    expand: { reise: {} },
    formatter: null,
    category: 'Reise',
  },
  region: {
    key: 'reise.region',
    label: 'Region',
    expand: { reise: {} },
    formatter: null,
    category: 'Reise',
  },
  reiseID: {
    key: 'reise.id',
    label: 'Reise ID',
    expand: { reise: {} },
    formatter: null,
    category: 'Reise',
  },
  hotelleistungen: {
    key: 'hotelleistungen',
    label: 'Hotelleistungen',
    expand: { hotelleistungen: {} },
    formatter: null,
    category: 'Sonstige',
  },
  comments: {
    key: 'comments',
    label: 'Kommentare',
    expand: { comments: {} },
    formatter: null,
    category: 'Sonstige',
  },
};
