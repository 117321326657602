export const SET_AUFGABEN_VERLINKUNG = 'zvc.setAufgabenVerlinkung';

const state = () => ({
  aufgabenVerlinkung: [],
});
const getters = {};

const actions = {};

const mutations = {
  [SET_AUFGABEN_VERLINKUNG](state, verlinkung) {
    state.aufgabenVerlinkung = verlinkung;
  },
};
export default {
  state,
  actions,
  mutations,
  getters,
};
