import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router.js';
import store from '@/core/common/services/vuex-store.js';
import apiService from '@/core/common/services/api.service';
import agGridService from '@/core/common/services/agGridService.js';
import allowedRoles from '@/core/common/directives/allowedRoles.js';
import { Role } from '@/core/user/roles.js';

import { INITIALIZE_STATE_STORE } from '@/core/common/stores/state-config.module';
import { VERIFY_AUTH, INITIALIZE_AUTH, TRY_SSO_SILENT_LOGIN } from '@/core/user/stores/auth.module.js';
import { RESET_LAYOUT_CONFIG } from '@/core/common/stores/config.module';
import { INITIALIZE_FLUGHAEFEN_STORE } from '@/core/flughaefen/stores/flughaefen.module';
import { GET_ACTIVE_UMFRAGE } from '@/core/umfragen/umfragen.module';
import {
  INITIALLY_GET_AUFGABEN_NOTIFICATION_COUNT,
  GET_TEAM_NOTIFICATION_COUNT,
} from '@/core/aufgaben/stores/aufgaben.module';
import { GET_USERS } from '@/core/user/stores/users.module';
import {
  INITIALIZE_REGIONSMANAGER,
  GET_REISEN_KUERZEL_FILTER_OPTIONS,
} from '@/core/produkte/reisen/stores/reisen.module';
import { isRoleMatchingRouteRoles } from '@/core/common/helpers/utils';

import * as applicationInsights from '@/core/common/services/application-insights.service';

Vue.config.productionTip = false;
// Global 3rd party plugins
import 'moment/locale/de';
import 'popper.js';
import 'tooltip.js';
import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from 'clipboard';
window.ClipboardJS = ClipboardJS;
import PortalVue from 'portal-vue';
import NProgress from 'vue-nprogress';

// Vue 3rd party plugins
import Vue2Filters from '@/core/common/plugins/vue2-filters.js';
import i18n from '@/core/common/plugins/vue-i18n.js';
import '@/core/common/plugins/portal-vue.js';
import '@/core/common/plugins/bootstrap-vue.js';
import '@/core/common/plugins/perfect-scrollbar.js';
import '@/core/common/plugins/inline-svg.js';
import '@/core/common/plugins/metronic.js';
import '@mdi/font/css/materialdesignicons.css';
import '@/core/common/helpers/toast.mixin';

applicationInsights.init();
agGridService.setLicenseKey();
// API service init
apiService.init();
// Remove this to disable mock API
//MockService.init();

//NProgress
const options = {
  latencyThreshold: 200, // Number of ms before progressbar starts showing, default: 100,
  router: false, // Show progressbar when navigating routes, default: true
  http: true, // Show progressbar when doing Vue.http, default: true
};
Vue.use(NProgress, options);
const nprogress = new NProgress();

store.dispatch(INITIALIZE_AUTH);

router.beforeEach((to, from, next) => {
  // Set window titles
  window.document.title = to.meta && to.meta.title ? to.meta.title : 'SKR Reisen | Manager';

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // set authorize from router
  const { authorize } = to.meta;

  // on /login just go on and do not check auth
  if (to.path !== '/login' && to.path !== '/403' && to.path !== '/404') {
    store
      .dispatch(VERIFY_AUTH)
      .then(auth => {
        const properties = {
          name: to.name,
          uri: to.fullPath,
          refUri: from.fullPath,
          properties: {
            appApiUrl: process.env.VUE_APP_API_URL,
            currentUser: auth?.account,
            currentUserName: auth?.account?.username,
            roles: auth?.account?.idTokenClaims?.roles,
          },
        };
        applicationInsights.trackPageView(properties);
        // if a user id is set but usersetting does not have one -> initialize it with the user id
        if (!auth.isLoginRequired && auth.account.localAccountId) {
          store.dispatch(GET_USERS).then(() => {
            store.dispatch(INITIALLY_GET_AUFGABEN_NOTIFICATION_COUNT);
            store.dispatch(GET_TEAM_NOTIFICATION_COUNT);
            store.dispatch(GET_ACTIVE_UMFRAGE);
          });
          store.dispatch(INITIALIZE_STATE_STORE);
          store.dispatch(INITIALIZE_FLUGHAEFEN_STORE);
          store.dispatch(INITIALIZE_REGIONSMANAGER);
          store.dispatch(GET_REISEN_KUERZEL_FILTER_OPTIONS);
        }

        if (!auth.isLoginRequired && authorize) {
          // authorize is set
          // check if userRoles match routeRoles
          // if it is not set -> just go on
          if (!isRoleMatchingRouteRoles(auth.account.idTokenClaims.roles, authorize)) {
            return next({
              name: 'not-authorized',
              params: { pageUrl: to.fullPath, requiredRoles: authorize },
            });
          } else {
            return next();
          }
        } else {
          return next();
        }
      })
      .catch(e => {
        store
          .dispatch(TRY_SSO_SILENT_LOGIN)
          .then(auth => {
            return auth;
          })
          .catch(e => {
            return next({ path: '/login' });
          });
      });
  } else {
    return next();
  }
  document.addEventListener('focusin', e => {
    if (e.target.closest('.tox-tinymce-aux, .moxman-window, .tam-assetmanager-root') !== null) {
      e.stopImmediatePropagation();
    }
  });
});

Vue.directive('allowedRoles', allowedRoles);
Vue.prototype.Role = Role;

export const app = new Vue({
  Vue2Filters,
  PortalVue,
  router,
  store,
  i18n,
  nprogress,
  render: h => h(App),
}).$mount('#app');
