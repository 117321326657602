<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import 'assets/plugins/flaticon/flaticon.css';
@import 'assets/plugins/flaticon2/flaticon.css';
@import 'assets/plugins/keenthemes-icons/font/ki.css';

// Main demo style scss
@import 'assets/sass/style.vue';
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from '@/core/common/stores/config.module';
import { DISPLAYED_ERROR_MSG } from '@/core/common/stores/error-handler.module.js';
import { mapState } from 'vuex';

export default {
  name: 'MetronicVue',
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/common/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  },
  watch: {
    errorModalMsg() {
      this.$bvModal.msgBoxOk(this.errorModalMsg, {
        title: 'Error',
        variant: this.errorType,
        size: 'lg',
        buttonSize: 'sm',
        centered: true,
      });
      this.$store.dispatch(DISPLAYED_ERROR_MSG);
    },
    errorNotificationMsg() {
      this.$bvToast.toast(this.errorNotificationMsg, {
        title: 'Error',
        variant: this.errorType,
        autoHideDelay: 2000,
        toaster: 'b-toaster-bottom-right',
        noCloseButton: true,
      });
      this.$store.dispatch(DISPLAYED_ERROR_MSG);
    },
  },
  computed: {
    ...mapState({
      errorModalMsg: state => state.errorHandler.errorModalMsg,
      errorNotificationMsg: state => state.errorHandler.errorNotificationMsg,
      errorType: state => state.errorHandler.errorType,
    }),
  },
};
</script>
