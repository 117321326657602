// import * as odataService from '@/core/common/services/odata.service';
import apiService from '@/core/common/services/api.service';
import { sub, add, format, getWeek, getYear, compareDesc } from 'date-fns';

import { calculateLastSaturday } from '@/core/common/helpers/utils';
import reisekuerzelOptions from '@/core/flugverfuegbarkeit/reisekuerzel-options.config.json';
import { allAkzeptanzgruende, allUrsachen } from '@/core/flugverfuegbarkeit/bearbeitungs-options.config.js';
import { getRegionsmanagerByShortname } from '../../common/helpers/utils';
// data
export const GET_GESAMTVERTEILUNG_STATUS_DATA = 'kpiFvc.getGesamtverteilungStatusData';
export const SET_GESAMTVERTEILUNG_STATUS_DATA = 'kpiFvc.setGesamtverteilungStatusData';

export const GET_AUFPREIS_BIGGER_200_DATA = 'kpiFvc.getAufpreisBigger200StatusData';
export const SET_AUFPREIS_BIGGER_200_DATA = 'kpiFvc.setAufpreisBigger200StatusData';

export const GET_AUFPREIS_SMALLER_200_DATA = 'kpiFvc.getAufpreisSmaller200StatusData';
export const SET_AUFPREIS_SMALLER_200_DATA = 'kpiFvc.setAufpreisSmaller200StatusData';

export const GET_NICHT_VERFUEGBAR_AKZEPTANZGRUND_DATA = 'kpiFvc.getNichtVerfuegbarAkzeptanzgrundData';
export const SET_NICHT_VERFUEGBAR_AKZEPTANZGRUND_DATA = 'kpiFvc.setNichtVerfuegbarAkzeptanzgrundData';

export const GET_AUF_ANFRAGE_AKZEPTANZGRUND_DATA = 'kpiFvc.getAufAnfrageAkzeptanzgrundData';
export const SET_AUF_ANFRAGE_AKZEPTANZGRUND_DATA = 'kpiFvc.setAufAnfrageAkzeptanzgrundData';

export const GET_DATA_WITH_ALL_GROUPINGS = 'kpiFvc.getDataWithAllGroupings';
export const SET_ALL_GROUPED_DATA = 'kpiFvc.setAllGroupedData';

export const GET_NEW_FILTER_DATA = 'kpiFvc.getNewFilterData';
export const SET_FILTERED_DATA = 'kpiFvc.setFilteredData';

export const GET_HISTORIC_DATA = 'kpiFvc.getHistoricData';
export const SET_HISTORIC_DATA = 'kpiFvc.getHistoricData';

// loading
export const SET_IS_BUSY = 'kpiFvc.setIsBusy';

// filters
export const SET_REGION_FILTER = 'kpiFvc.setRegionFilter';
export const RESET_REGION_FILTER = 'kpiFvc.resetRegionFilter';

export const SET_COUNTRY_FILTER = 'kpiFvc.setCountryFilter';
export const RESET_COUNTRY_FILTER = 'kpiFvc.resetCountryFilter';

export const SET_REISEN_FILTER = 'kpiFvc.setReisenFilter';
export const RESET_REISEN_FILTER = 'kpiFvc.resetReisenFilter';

export const SET_DEUTSCHER_ABFLUGHAFEN_FILTER = 'kpiFvc.setDeutscherAbflughafenFilter';
export const RESET_DEUTSCHER_ABFLUGHAFEN_FILTER = 'kpiFvc.resetDeutscherAbflughafenFilter';

export const SET_PRIORITAET_FILTER = 'kpiFvc.setPrioritaetFilter';
export const RESET_PRIORITAET_FILTER = 'kpiFvc.resetPrioritaetFilter';

export const SET_REGIONSMANAGER_FILTER = 'kpiFvc.setRegionsmanagerFilter';
export const RESET_REGIONSMANAGER_FILTER = 'kpiFvc.resetRegionsmanagerFilter';

export const SET_NUR_AKZEPTIERT_FILTER = 'kpiFvc.setAkzeptiertFilter';

export const SET_ZEITRAUM_FILTER = 'kpiFvc.setZeitraumFilter';

export const RESET_ALL_FILTERS = 'kpiFvc.resetAllFilters';

const state = () => ({
  reiseFilterOptions: reisekuerzelOptions,
  isBusy: false,
  filters: {
    reisen: [],
    deutscherAbflughafen: [],
    prioritaet: [],
    region: [],
    country: [],
    zeitraum: { start: null, end: null },
    regionsmanager: [],
    nurAkzeptiert: false,
  },
  data: {
    allGroupedData: [],
    filteredData: [],
    historicData: [],
  },
});
const getters = {
  getAllGroupedData(state) {
    return state.data.allGroupedData;
  },
  getAllHistoricData(state) {
    return state.data.historicData;
  },
  getFilteredData(state, rootGetters) {
    let data = [...state.data.allGroupedData];
    const filledFilters = Object.keys(state.filters)
      .filter(filterKey => Array.isArray(state.filters[filterKey]) && state.filters[filterKey].length > 0)
      .map(filterKey => {
        return { filterArray: state.filters[filterKey], filterKey };
      });
    filledFilters.forEach(filter => {
      if (filter.filterKey === 'region') {
        data = data.filter(item => filter.filterArray.includes(item.region));
      }
      if (filter.filterKey === 'reisen') {
        const getAllSelectedReisekuerzel = filter.filterArray.map(item => item.kuerzelLabel);
        data = data.filter(item => getAllSelectedReisekuerzel.includes(item.reisekuerzel));
      }
      if (filter.filterKey === 'prioritaet') {
        const getAllSelectedPrioritaeten = filter.filterArray;
        data = data.filter(item => getAllSelectedPrioritaeten.includes(item.prioritaet));
      }
      if (filter.filterKey === 'deutscherAbflughafen') {
        const getAllSelectedAbflughaefen = filter.filterArray;
        data = data.filter(item => getAllSelectedAbflughaefen.includes(item.deutscherAbflughafen));
      }
      if (filter.filterKey === 'regionsmanager') {
        const getAllSelectedRegionsmanager = filter.filterArray;
        const regionsmanagerIds = getAllSelectedRegionsmanager.map(
          rm => getRegionsmanagerByShortname(rm)?.[0]
        );
        const regionsmanagerConfigCore = rootGetters.getRegionsmanagerConfig;
        const regionsmanagerAssignedReisen = regionsmanagerIds
          .map(regionsmanagerId => regionsmanagerConfigCore[regionsmanagerId])
          .flat();
        data = data.filter(item => regionsmanagerAssignedReisen.includes(item.reisekuerzel));
      }
    });
    if (state.filters.nurAkzeptiert) {
      data = data.filter(item => item.bearbeitungStatus === 'Akzeptiert');
    }
    return data;
  },
  getFilteredHistoricData(state, rootGetters) {
    let data = [...state.data.historicData];
    data = data.map(weekData => {
      const filledFilters = Object.keys(state.filters)
        .filter(filterKey => Array.isArray(state.filters[filterKey]) && state.filters[filterKey].length > 0)
        .map(filterKey => {
          return { filterArray: state.filters[filterKey], filterKey };
        });
      filledFilters.forEach(filter => {
        if (filter.filterKey === 'region') {
          weekData = weekData.filter(item => filter.filterArray.includes(item.region));
        }
        if (filter.filterKey === 'reisen') {
          const getAllSelectedReisekuerzel = filter.filterArray.map(item => item.kuerzelLabel);
          weekData = weekData.filter(item => getAllSelectedReisekuerzel.includes(item.reisekuerzel));
        }
        if (filter.filterKey === 'prioritaet') {
          const getAllSelectedPrioritaeten = filter.filterArray;
          weekData = weekData.filter(item => getAllSelectedPrioritaeten.includes(item.prioritaet));
        }
        if (filter.filterKey === 'deutscherAbflughafen') {
          const getAllSelectedAbflughaefen = filter.filterArray;
          weekData = weekData.filter(item => getAllSelectedAbflughaefen.includes(item.deutscherAbflughafen));
        }
        if (filter.filterKey === 'regionsmanager') {
          const getAllSelectedRegionsmanager = filter.filterArray;
          const regionsmanagerIds = getAllSelectedRegionsmanager.map(
            rm => getRegionsmanagerByShortname(rm)?.[0]
          );
          const regionsmanagerConfigCore = rootGetters.getRegionsmanagerConfig;
          const regionsmanagerAssignedReisen = regionsmanagerIds
            .map(regionsmanagerId => regionsmanagerConfigCore[regionsmanagerId])
            .flat();
          weekData = weekData.filter(item => regionsmanagerAssignedReisen.includes(item.reisekuerzel));
          console.log(weekData);
        }
      });
      if (state.filters.nurAkzeptiert) {
        weekData = weekData.filter(item => item.bearbeitungStatus === 'Akzeptiert');
      }
      return weekData;
    });
    return data;
  },
  getColumnChartAkzeptanzgrundData(state, getters) {
    if (
      getters.getNichtVerfuegbarAkzeptanzgrundData.length > 0 &&
      getters.getAufpreisBigger200AkzeptanzgrundData.length > 0 &&
      getters.getAufAnfrageAkzeptanzgrundData.length > 0
    ) {
      const formatNichtVerfuegbarAkzeptanzgrundData = getters.getNichtVerfuegbarAkzeptanzgrundData
        .filter(item => item.type !== 'Nicht akzeptiert')
        .reduce((acc, curr) => ({ ...acc, [curr.type]: curr.count }), {});
      console.log(formatNichtVerfuegbarAkzeptanzgrundData);
      const formatAufpreisBigger200AkzeptanzgrundData = getters.getAufpreisBigger200AkzeptanzgrundData
        .filter(item => item.type !== 'Nicht akzeptiert')
        .reduce((acc, curr) => ({ ...acc, [curr.type]: curr.count }), {});
      const formatAufAnfrageAkzeptanzgrundData = getters.getAufAnfrageAkzeptanzgrundData
        .filter(item => item.type !== 'Nicht akzeptiert')
        .reduce((acc, curr) => ({ ...acc, [curr.type]: curr.count }), {});
      const yAxisMaxValue = Math.max(
        Object.values(formatNichtVerfuegbarAkzeptanzgrundData).reduce((acc, curr) => (acc += curr), 0),
        Object.values(formatAufpreisBigger200AkzeptanzgrundData).reduce((acc, curr) => (acc += curr), 0),
        Object.values(formatAufAnfrageAkzeptanzgrundData).reduce((acc, curr) => (acc += curr), 0)
      );

      return [
        {
          ...formatNichtVerfuegbarAkzeptanzgrundData,
          xKey: 'Nicht verfügbar',
          totalCount: getters.getNichtVerfuegbarAkzeptanzgrundData
            .filter(item => item.type !== 'Nicht akzeptiert')
            .reduce((acc, curr) => (acc += curr.count), 0),
          yAxisMaxValue,
        },
        {
          ...formatAufAnfrageAkzeptanzgrundData,
          xKey: 'Auf Anfrage',
          totalCount: getters.getAufAnfrageAkzeptanzgrundData
            .filter(item => item.type !== 'Nicht akzeptiert')
            .reduce((acc, curr) => (acc += curr.count), 0),
          yAxisMaxValue,
        },
        {
          ...formatAufpreisBigger200AkzeptanzgrundData,
          xKey: 'Aufpreis >200',
          totalCount: getters.getAufpreisBigger200AkzeptanzgrundData
            .filter(item => item.type !== 'Nicht akzeptiert')
            .reduce((acc, curr) => (acc += curr.count), 0),
          yAxisMaxValue,
        },
      ];
    } else return [];
  },
  getColumnChartUrsachenData(state, getters) {
    const data = getters.getFilteredData;
    const statusOptions = ['NichtVerfuegbar', 'AufAnfrage', 'Verfuegbar'];
    const formattedData = statusOptions.map(status => {
      const filteredUrsachenData = data.filter(item => item.status === status && item.ursache !== null);
      const countUrsachenStatus = getCount(filteredUrsachenData);
      const allUrsachenInData = filteredUrsachenData.reduce((acc, abfht) => {
        if (!acc.includes(abfht.ursache)) {
          acc.push(abfht.ursache);
        }
        return acc;
      }, []);
      const ursachenWithCounts = allUrsachenInData.map(ursache => {
        const ursachenLabel = allUrsachen.find(item => item.enum === ursache).label;
        return {
          [ursachenLabel]: filteredUrsachenData
            .filter(item => item.ursache === ursache)
            .reduce((acc, curr) => (acc += curr.count), 0),
        };
      });

      return {
        ...Object.assign({}, ...ursachenWithCounts),
        xKey:
          status === 'NichtVerfuegbar'
            ? 'Nicht verfügbar'
            : status === 'Verfuegbar'
            ? 'Aufpreis > 200'
            : 'Auf Anfrage',
        totalCount:
          status === 'Verfuegbar'
            ? getCount(
                data.filter(item => item.status === status && item.aufpreis > 200 && item.ursache !== null)
              )
            : getCount(data.filter(item => item.status === status && item.ursache !== null)),
      };
    });
    const yAxisMaxValue =
      formattedData.length > 0
        ? formattedData.reduce((acc, curr) => (acc > curr.totalCount ? acc : curr.totalCount), 0)
        : 0;
    return formattedData.map(item => ({ ...item, yAxisMaxValue }));
  },
  getGesamtverteilungStatusData(state, getters) {
    const data = getters.getFilteredData;

    const aufAnfrageABFHT = data.filter(item => item.status === 'AufAnfrage');
    const nichtVerfuegbareABFHT = data.filter(item => item.status === 'NichtVerfuegbar');
    const verfuegbareABFHT = data.filter(item => item.status === 'Verfuegbar');
    const totalCountVerfuegbarSmaller200 = getCount(verfuegbareABFHT.filter(item => item.aufpreis <= 200));
    const totalCountVerfuegbarBigger200 = getCount(verfuegbareABFHT.filter(item => item.aufpreis > 200));

    const gesamtverteilungStatus = [
      { type: 'Auf Anfrage', count: getCount(aufAnfrageABFHT) },
      { type: 'Nicht verfügbar', count: getCount(nichtVerfuegbareABFHT) },
      { type: 'Aufpreis >200', count: totalCountVerfuegbarBigger200 },
      { type: 'Aufpreis <200', count: totalCountVerfuegbarSmaller200 },
    ];

    return gesamtverteilungStatus;
  },
  getAufpreisBigger200Data(state, getters) {
    const data = getters.getFilteredData;

    const verfuegbareABFHT = data.filter(item => item.status === 'Verfuegbar');
    const countAufpreis201To300 = getCount(
      verfuegbareABFHT.filter(item => item.aufpreis > 200 && item.aufpreis <= 300)
    );
    const countAufpreis301To500 = getCount(
      verfuegbareABFHT.filter(item => item.aufpreis > 300 && item.aufpreis <= 500)
    );
    const countAufpreisBigger500 = getCount(verfuegbareABFHT.filter(item => item.aufpreis > 500));
    const dataAufpreisBigger200 = [
      { type: 'Aufpreis 201-300', count: countAufpreis201To300 },
      { type: 'Aufpreis 301-500', count: countAufpreis301To500 },
      { type: 'Aufpreis >500', count: countAufpreisBigger500 },
    ];
    return dataAufpreisBigger200;
  },
  getAufpreisSmaller200Data(state, getters) {
    const data = getters.getFilteredData;

    const verfuegbareABFHT = data.filter(item => item.status === 'Verfuegbar');

    const countKeinAufpreis = getCount(verfuegbareABFHT.filter(item => item.aufpreis <= 0));
    const countAufpreis0To100 = getCount(
      verfuegbareABFHT.filter(item => item.aufpreis > 0 && item.aufpreis <= 100)
    );
    const countAufpreis101To200 = getCount(
      verfuegbareABFHT.filter(item => item.aufpreis > 100 && item.aufpreis <= 200)
    );
    const dataAufpreisSmaller200 = [
      { type: 'Aufpreis 101-200', count: countAufpreis101To200 },
      { type: 'Aufpreis 0-100', count: countAufpreis0To100 },
      { type: 'Kein Aufpreis', count: countKeinAufpreis },
    ];
    return dataAufpreisSmaller200;
  },
  getNichtVerfuegbarAkzeptanzgrundData(state, getters) {
    const data = getters.getFilteredData;
    const nichtVerfuegbareABFHT = data.filter(item => item.status === 'NichtVerfuegbar');

    const getAllAkzeptanzgruendeNichtVerfuegbar = nichtVerfuegbareABFHT.reduce((acc, abfht) => {
      if (!acc.includes(abfht.akzeptanzgrund)) {
        acc.push(abfht.akzeptanzgrund);
      }
      return acc;
    }, []);

    const dataNichtVerfuegbarAkzeptanzgrund = getAllAkzeptanzgruendeNichtVerfuegbar.map(akzeptanzgrund => {
      return {
        type: allAkzeptanzgruende.find(item => item.enum === akzeptanzgrund)?.label || 'Nicht akzeptiert',
        count: getCount(nichtVerfuegbareABFHT.filter(item => item.akzeptanzgrund === akzeptanzgrund)),
      };
    });
    return dataNichtVerfuegbarAkzeptanzgrund;
  },
  getAufAnfrageAkzeptanzgrundData(state, getters) {
    const data = getters.getFilteredData;

    const aufAnfrageABFHT = data.filter(item => item.status === 'AufAnfrage');

    const getAllAkzeptanzgruendeAufAnfrage = aufAnfrageABFHT.reduce((acc, abfht) => {
      if (!acc.includes(abfht.akzeptanzgrund)) {
        acc.push(abfht.akzeptanzgrund);
      }
      return acc;
    }, []);

    const dataAufAnfrageAkzeptanzgrund = getAllAkzeptanzgruendeAufAnfrage.map(akzeptanzgrund => {
      return {
        type: allAkzeptanzgruende.find(item => item.enum === akzeptanzgrund)?.label || 'Nicht akzeptiert',
        count: getCount(aufAnfrageABFHT.filter(item => item.akzeptanzgrund === akzeptanzgrund)),
      };
    });
    return dataAufAnfrageAkzeptanzgrund;
  },
  getAufpreisBigger200AkzeptanzgrundData(state, getters) {
    const data = getters.getFilteredData;

    const verfuegbareABFHTAufpreisBigger200 = data.filter(
      item => item.status === 'Verfuegbar' && item.aufpreis > 200
    );

    const getAllAkzeptanzgruendeAufpreisBigger200 = verfuegbareABFHTAufpreisBigger200.reduce((acc, abfht) => {
      if (!acc.includes(abfht.akzeptanzgrund)) {
        acc.push(abfht.akzeptanzgrund);
      }
      return acc;
    }, []);

    const dataAufpreisBigger200Akzeptanzgrund = getAllAkzeptanzgruendeAufpreisBigger200.map(
      akzeptanzgrund => {
        return {
          type: allAkzeptanzgruende.find(item => item.enum === akzeptanzgrund)?.label || 'Nicht akzeptiert',
          count: getCount(
            verfuegbareABFHTAufpreisBigger200.filter(item => item.akzeptanzgrund === akzeptanzgrund)
          ),
        };
      }
    );
    return dataAufpreisBigger200Akzeptanzgrund;
  },
  getBearbeitungsData(state, getters) {
    const data = getters.getFilteredData;
    const alleZuBearbeitendenAbfhtCount = getCount(
      data.filter(item => !(item.status === 'Verfuegbar' && item.aufpreis <= 200))
    );
    const bearbeiteteAbfhtCount = getCount(data.filter(item => item.bearbeitungStatus !== null));
    const akzeptierteAbfhtManuellCount = getCount(
      data.filter(item => item.bearbeitungStatus === 'Akzeptiert' && item.lastModifiedUserId !== null)
    );
    const akzeptierteAbfhtAutomatischCount = getCount(
      data.filter(item => item.bearbeitungStatus === 'Akzeptiert' && item.lastModifiedUserId === null)
    );
    const verfuegbarkeitNichtGeschaffenAbfhtAutomatischCount = getCount(
      data.filter(
        item => item.bearbeitungStatus === 'VerfuegbarkeitNichtGeschaffen' && item.lastModifiedUserId === null
      )
    );
    const verfuegbarkeitNichtGeschaffenAbfhtManuellCount = getCount(
      data.filter(
        item => item.bearbeitungStatus === 'VerfuegbarkeitNichtGeschaffen' && item.lastModifiedUserId !== null
      )
    );
    const verfuegbarkeitGeschaffenAbfhtManuellCount = getCount(
      data.filter(
        item => item.bearbeitungStatus === 'VerfuegbarkeitGeschaffen' && item.lastModifiedUserId !== null
      )
    );
    const verfuegbarkeitGeschaffenAbfhtAutomatischCount = getCount(
      data.filter(
        item => item.bearbeitungStatus === 'VerfuegbarkeitGeschaffen' && item.lastModifiedUserId === null
      )
    );
    const nichtBearbeiteteAbfhtCount = alleZuBearbeitendenAbfhtCount - bearbeiteteAbfhtCount;
    return [
      {
        type: 'Verfügbarkeit geschaffen (automatisch)',
        count: verfuegbarkeitGeschaffenAbfhtAutomatischCount,
      },
      {
        type: 'Verfügbarkeit geschaffen (manuell)',
        count: verfuegbarkeitGeschaffenAbfhtManuellCount,
      },
      {
        type: 'Verfügbarkeit nicht geschaffen (automatisch)',
        count: verfuegbarkeitNichtGeschaffenAbfhtAutomatischCount,
      },
      {
        type: 'Verfügbarkeit nicht geschaffen (manuell)',
        count: verfuegbarkeitNichtGeschaffenAbfhtManuellCount,
      },
      {
        type: 'Akzeptiert (automatisch)',
        count: akzeptierteAbfhtAutomatischCount,
      },
      {
        type: 'Akzeptiert (manuell)',
        count: akzeptierteAbfhtManuellCount,
      },
      {
        type: 'Nicht bearbeitet',
        count: nichtBearbeiteteAbfhtCount,
      },
    ];
  },
  getVerfuegbarkeitsData(state, getters) {
    const data = getters.getFilteredData;
    const bearbeiteteAbfhtCount = getCount(data.filter(item => item.bearbeitungStatus !== null));
    const verfuegbarkeitGeschaffenAbfhtCount = getCount(
      data.filter(item => item.bearbeitungStatus === 'VerfuegbarkeitGeschaffen')
    );
    return [
      { type: 'Anders bearbeitet', count: bearbeiteteAbfhtCount - verfuegbarkeitGeschaffenAbfhtCount },
      { type: 'Verfügbarkeit geschaffen', count: verfuegbarkeitGeschaffenAbfhtCount },
    ];
  },
  getTopFiveUrsachen(state, getters) {
    const data = getters.getFilteredData;
    const ursachenCount = getCount(data.filter(item => item.ursache !== null));

    const getUrsachenCounts = allUrsachen
      .map(ursache => {
        const ursacheCount = getCount(data.filter(item => item.ursache === ursache.enum));
        return {
          count: ursacheCount,
          ursache: ursache.label.length > 15 ? ursache.label.substring(0, 15) + '..' : ursache.label,
        };
      })
      .filter(item => item.count > 0)
      .sort((a, b) => b.count - a.count);
    let slicedUrsachenCounts;
    if (getUrsachenCounts.length > 5) {
      slicedUrsachenCounts = getUrsachenCounts.slice(0, 5);
    } else slicedUrsachenCounts = getUrsachenCounts;

    return { allCount: ursachenCount, data: slicedUrsachenCounts };
  },
  getLeadingKPIsStatusNachBearbeitung(state, getters) {
    const euRegions = ['Südeuropa', 'Nordeuropa', 'Dach'];
    const fernRegions = ['Asien', 'Amerika', 'Ozeanien', 'Afrika', 'Orient'];
    const data = getters.getFilteredData;

    const getStatusNachBearbeitungData = data => {
      const nichtAkzeptierteRqs = data.filter(
        item => item.status === 'AufAnfrage' && item.bearbeitungStatus !== 'Akzeptiert'
      );
      const bearbeiteteNs = data.filter(
        item => item.status === 'NichtVerfuegbar' && item.bearbeitungStatus !== null
      );
      const akzeptierteRqs = data.filter(
        item => item.status === 'AufAnfrage' && item.bearbeitungStatus === 'Akzeptiert'
      );
      const aufpreisSmaller200 = data.filter(item => item.status === 'Verfuegbar' && item.aufpreis < 200);
      const nichtAkzeptierteAufpreisBigger200 = data.filter(
        item =>
          item.status === 'Verfuegbar' && item.aufpreis >= 200 && item.bearbeitungStatus !== 'Akzeptiert'
      );
      const akzeptierteAufpreiseBigger200 = data.filter(
        item => item.status === 'Verfuegbar' && item.aufpreis > 200 && item.bearbeitungStatus === 'Akzeptiert'
      );

      const gesamtverteilungStatus = [
        { type: 'Nicht akzeptierte RQs', count: getCount(nichtAkzeptierteRqs) },
        { type: 'Nicht akzeptierte Aufpreis >200', count: getCount(nichtAkzeptierteAufpreisBigger200) },
        { type: 'Bearbeitete Ns', count: getCount(bearbeiteteNs) },
        { type: 'Aufpreis <200', count: getCount(aufpreisSmaller200) },
        { type: 'Akzeptierte RQs', count: getCount(akzeptierteRqs) },
        { type: 'Akzeptierte Aufpreis >200', count: getCount(akzeptierteAufpreiseBigger200) },
      ];
      return gesamtverteilungStatus;
    };

    const fraFernAD = data.filter(
      group => group.deutscherAbflughafen === 'FRA' && fernRegions.includes(group.region)
    );
    const mucBerFernAD = data.filter(
      group => ['MUC', 'BER'].includes(group.deutscherAbflughafen) && fernRegions.includes(group.region)
    );
    const hamDusMucBerEuAD = data.filter(
      group =>
        ['HAM', 'DUS', 'MUC', 'BER'].includes(group.deutscherAbflughafen) && euRegions.includes(group.region)
    );

    const statusNachBearbeitungFraFernAD = getStatusNachBearbeitungData(fraFernAD);
    const statusNachBearbeitungMucBerFernAD = getStatusNachBearbeitungData(mucBerFernAD);
    const statusNachBearbeitungHamDusMucBerEuAD = getStatusNachBearbeitungData(hamDusMucBerEuAD);

    return {
      statusNachBearbeitungFraFernAD,
      statusNachBearbeitungHamDusMucBerEuAD,
      statusNachBearbeitungMucBerFernAD,
    };
  },
  getLeadingKPIs(state, getters) {
    const euRegions = ['Südeuropa', 'Nordeuropa', 'Dach'];
    const fernRegions = ['Asien', 'Amerika', 'Ozeanien', 'Afrika', 'Orient'];
    const getGesamtverteilungStatusData = data => {
      const aufAnfrageABFHT = data.filter(item => item.status === 'AufAnfrage');
      const nichtVerfuegbareABFHT = data.filter(item => item.status === 'NichtVerfuegbar');
      const verfuegbareABFHT = data.filter(item => item.status === 'Verfuegbar');
      const totalCountVerfuegbarSmaller200 = getCount(verfuegbareABFHT.filter(item => item.aufpreis <= 200));
      const totalCountVerfuegbarBigger200 = getCount(verfuegbareABFHT.filter(item => item.aufpreis > 200));

      const gesamtverteilungStatus = [
        { type: 'Auf Anfrage', count: getCount(aufAnfrageABFHT) },
        { type: 'Nicht verfügbar', count: getCount(nichtVerfuegbareABFHT) },
        { type: 'Aufpreis >200', count: totalCountVerfuegbarBigger200 },
        { type: 'Aufpreis <200', count: totalCountVerfuegbarSmaller200 },
      ];
      return gesamtverteilungStatus;
    };
    const data = getters.getFilteredData;
    if (data.length === 0) {
      return null;
    }
    const mucBer_AD_fern = data.filter(
      group =>
        (group.deutscherAbflughafen === 'MUC' || group.deutscherAbflughafen === 'BER') &&
        fernRegions.includes(group.region)
    );
    const hamDusBerMuc_AD_europa = data.filter(
      group =>
        ['HAM', 'DUS', 'BER', 'MUC'].includes(group.deutscherAbflughafen) && euRegions.includes(group.region)
    );
    const frankfurtGesamt = data.filter(group => group.deutscherAbflughafen === 'FRA');
    const frankfurtFern = data.filter(
      group => group.deutscherAbflughafen === 'FRA' && fernRegions.includes(group.region)
    );
    const frankfurtEu = data.filter(
      group => group.deutscherAbflughafen === 'FRA' && euRegions.includes(group.region)
    );
    const fraMucGesamt = data.filter(
      group =>
        group.deutscherAbflughafen === 'FRA' ||
        (group.deutscherAbflughafen === 'MUC' && [0, 1].includes(group.prioritaet))
    );
    const fraMucFern = data.filter(
      group =>
        (group.deutscherAbflughafen === 'FRA' ||
          (group.deutscherAbflughafen === 'MUC' && [0, 1].includes(group.prioritaet))) &&
        fernRegions.includes(group.region)
    );
    const fraMucEu = data.filter(
      group =>
        (group.deutscherAbflughafen === 'FRA' ||
          (group.deutscherAbflughafen === 'MUC' && [0, 1].includes(group.prioritaet))) &&
        euRegions.includes(group.region)
    );

    const gesamtverteilungStatusHamDusBerMucAD = getGesamtverteilungStatusData(hamDusBerMuc_AD_europa);
    const gesamtverteilungStatusMuenchenBerlinAD = getGesamtverteilungStatusData(mucBer_AD_fern);
    const gesamtverteilungStatusFrankfurtGesamt = getGesamtverteilungStatusData(frankfurtGesamt);
    const gesamtverteilungStatusFrankfurtFern = getGesamtverteilungStatusData(frankfurtFern);
    const gesamtverteilungStatusFrankfurtEu = getGesamtverteilungStatusData(frankfurtEu);
    const gesamtverteilungStatusFraMucGesamt = getGesamtverteilungStatusData(fraMucGesamt);
    const gesamtverteilungStatusFraMucFern = getGesamtverteilungStatusData(fraMucFern);
    const gesamtverteilungStatusFraMucEu = getGesamtverteilungStatusData(fraMucEu);

    return {
      gesamtverteilungStatusHamDusBerMucAD,
      gesamtverteilungStatusMuenchenBerlinAD,
      gesamtverteilungStatusFrankfurtGesamt,
      gesamtverteilungStatusFrankfurtFern,
      gesamtverteilungStatusFrankfurtEu,
      gesamtverteilungStatusFraMucGesamt,
      gesamtverteilungStatusFraMucFern,
      gesamtverteilungStatusFraMucEu,
    };
  },
  getHistoricGesamtverteilungStatusData(state, getters) {
    const data = getters.getFilteredHistoricData;

    return data
      .map(weekData => {
        const aufAnfrageABFHT = weekData.filter(item => item.status === 'AufAnfrage');
        const nichtVerfuegbareABFHT = weekData.filter(item => item.status === 'NichtVerfuegbar');
        const verfuegbareABFHT = weekData.filter(item => item.status === 'Verfuegbar');
        const totalCountVerfuegbarSmaller200 = getCount(verfuegbareABFHT.filter(item => item.aufpreis < 200));
        const totalCountVerfuegbarBigger200 = getCount(verfuegbareABFHT.filter(item => item.aufpreis > 200));
        const weekOfYear =
          weekData[0] && weekData[0].hasOwnProperty('date') ? getWeek(new Date(weekData[0].date.start)) : '';
        const totalCount =
          getCount(aufAnfrageABFHT) +
          getCount(nichtVerfuegbareABFHT) +
          totalCountVerfuegbarBigger200 +
          totalCountVerfuegbarSmaller200;
        const gesamtverteilungStatus = {
          'Auf Anfrage': getCount(aufAnfrageABFHT),
          'Nicht verfügbar': getCount(nichtVerfuegbareABFHT),
          'Aufpreis >200': totalCountVerfuegbarBigger200,
          'Aufpreis <200': totalCountVerfuegbarSmaller200,
          week: weekOfYear,
          weekStartDate: weekData[0]?.date?.start,
          xKey: 'KW' + weekOfYear.toString(),
          totalCount,
        };

        return gesamtverteilungStatus;
      })
      .sort((a, b) => compareDesc(new Date(b.weekStartDate), new Date(a.weekStartDate)));
  },
  getHistoricAufpreisGt200(state, getters) {
    const data = getters.getFilteredHistoricData;

    return data
      .map(weekData => {
        const verfuegbareABFHT = weekData.filter(item => item.status === 'Verfuegbar');
        const countAufpreis201To300 = getCount(
          verfuegbareABFHT.filter(item => item.aufpreis > 200 && item.aufpreis <= 300)
        );
        const countAufpreis301To500 = getCount(
          verfuegbareABFHT.filter(item => item.aufpreis > 300 && item.aufpreis <= 500)
        );
        const countAufpreisBigger500 = getCount(verfuegbareABFHT.filter(item => item.aufpreis > 500));
        const weekOfYear =
          weekData[0] && weekData[0].hasOwnProperty('date') ? getWeek(new Date(weekData[0].date.start)) : '';
        const dataAufpreisBigger200 = {
          'Aufpreis 201-300': countAufpreis201To300,
          'Aufpreis 301-500': countAufpreis301To500,
          'Aufpreis >500': countAufpreisBigger500,
          weekStartDate: weekData[0]?.date?.start,
          week: weekOfYear,
          xKey: 'KW' + weekOfYear.toString(),
          totalCount: countAufpreis201To300 + countAufpreis301To500 + countAufpreisBigger500,
        };
        return dataAufpreisBigger200;
      })
      .sort((a, b) => compareDesc(new Date(b.weekStartDate), new Date(a.weekStartDate)));
  },
  getHistoricAufpreisLt200(state, getters) {
    const data = getters.getFilteredHistoricData;
    return data
      .map(weekData => {
        const verfuegbareABFHT = weekData.filter(item => item.status === 'Verfuegbar');

        const countKeinAufpreis = getCount(verfuegbareABFHT.filter(item => item.aufpreis <= 0));
        const countAufpreis0To100 = getCount(
          verfuegbareABFHT.filter(item => item.aufpreis > 0 && item.aufpreis <= 100)
        );
        const countAufpreis101To200 = getCount(
          verfuegbareABFHT.filter(item => item.aufpreis > 100 && item.aufpreis <= 200)
        );
        const weekOfYear =
          weekData[0] && weekData[0].hasOwnProperty('date') ? getWeek(new Date(weekData[0].date.start)) : '';
        const dataAufpreisSmaller200 = {
          'Aufpreis 101-200': countAufpreis101To200,
          'Aufpreis 0-100': countAufpreis0To100,
          'Kein Aufpreis': countKeinAufpreis,
          week: weekOfYear,
          xKey: 'KW' + weekOfYear.toString(),
          weekStartDate: weekData[0]?.date?.start,
          totalCount: countAufpreis101To200 + countAufpreis0To100 + countKeinAufpreis,
        };
        return dataAufpreisSmaller200;
      })
      .sort((a, b) => compareDesc(new Date(b.weekStartDate), new Date(a.weekStartDate)));
  },
  getHistoryNichtVerfuegbarAkzeptanzgrund(state, getters) {
    const data = getters.getFilteredHistoricData;
    const formattedData = data
      .map(weekData => {
        let result = {};

        const nichtVerfuegbareABFHT = weekData.filter(item => item.status === 'NichtVerfuegbar');

        const getAllAkzeptanzgruendeNichtVerfuegbar = nichtVerfuegbareABFHT.reduce((acc, abfht) => {
          if (!acc.includes(abfht.akzeptanzgrund)) {
            acc.push(abfht.akzeptanzgrund);
          }
          return acc;
        }, []);
        const totalCount = nichtVerfuegbareABFHT
          .filter(item => item.akzeptanzgrund !== null)
          .reduce((acc, curr) => (acc += curr.count), 0);
        getAllAkzeptanzgruendeNichtVerfuegbar.forEach(akzeptanzgrund => {
          if (akzeptanzgrund) {
            const label = allAkzeptanzgruende.find(item => item.enum === akzeptanzgrund)?.label;
            result = {
              ...result,
              [label]: getCount(nichtVerfuegbareABFHT.filter(item => item.akzeptanzgrund === akzeptanzgrund)),
            };
          }
        });
        const weekOfYear =
          weekData[0] && weekData[0].hasOwnProperty('date') ? getWeek(new Date(weekData[0].date.start)) : '';
        result = {
          ...result,
          week: weekOfYear,
          weekStartDate: weekData[0]?.date?.start,
          xKey: 'KW' + weekOfYear.toString(),
          totalCount,
        };
        return result;
      })
      .sort((a, b) => compareDesc(new Date(b.weekStartDate), new Date(a.weekStartDate)));
    const yAxisMaxValue = formattedData.reduce(
      (acc, curr) => (acc > curr.totalCount ? acc : curr.totalCount),
      0
    );
    return formattedData.map(item => ({ ...item, yAxisMaxValue }));
  },
  getHistoryAufAnfrageAkzeptanzgrund(state, getters) {
    const data = getters.getFilteredHistoricData;
    const formattedData = data
      .map(weekData => {
        let result = {};
        const aufAnfrageABFHT = weekData.filter(item => item.status === 'AufAnfrage');

        const getAllAkzeptanzgruendeAufAnfrage = aufAnfrageABFHT.reduce((acc, abfht) => {
          if ((abfht.akzeptanzgrund !== null) & !acc.includes(abfht.akzeptanzgrund)) {
            acc.push(abfht.akzeptanzgrund);
          }
          return acc;
        }, []);
        const totalCount = aufAnfrageABFHT
          .filter(item => item.akzeptanzgrund !== null)
          .reduce((acc, curr) => (acc += curr.count), 0);
        getAllAkzeptanzgruendeAufAnfrage.forEach(akzeptanzgrund => {
          const label = allAkzeptanzgruende.find(item => item.enum === akzeptanzgrund)?.label;
          result = {
            ...result,
            [label]: getCount(aufAnfrageABFHT.filter(item => item.akzeptanzgrund === akzeptanzgrund)),
          };
        });
        const weekOfYear =
          weekData[0] && weekData[0].hasOwnProperty('date') ? getWeek(new Date(weekData[0].date.start)) : '';
        result = {
          ...result,
          week: weekOfYear,
          xKey: 'KW' + weekOfYear.toString(),
          weekStartDate: weekData[0]?.date?.start,
          totalCount,
        };
        return result;
      })
      .sort((a, b) => compareDesc(new Date(b.weekStartDate), new Date(a.weekStartDate)));
    const yAxisMaxValue = formattedData.reduce(
      (acc, curr) => (acc > curr.totalCount ? acc : curr.totalCount),
      0
    );
    return formattedData.map(item => ({ ...item, yAxisMaxValue }));
  },
  getHistoryAufpreisGt200Akzeptanzgrund(state, getters) {
    const data = getters.getFilteredHistoricData;
    const formattedData = data
      .map(weekData => {
        let result = {};
        const gt200Abfht = weekData.filter(item => item.status === 'Verfuegbar' && item.aufpreis > 200);

        const getAllAkzeptanzgruendeGt200 = gt200Abfht.reduce((acc, abfht) => {
          if ((abfht.akzeptanzgrund !== null) & !acc.includes(abfht.akzeptanzgrund)) {
            acc.push(abfht.akzeptanzgrund);
          }
          return acc;
        }, []);
        const totalCount = gt200Abfht
          .filter(item => item.akzeptanzgrund !== null)
          .reduce((acc, curr) => (acc += curr.count), 0);
        getAllAkzeptanzgruendeGt200.forEach(akzeptanzgrund => {
          const label = allAkzeptanzgruende.find(item => item.enum === akzeptanzgrund)?.label;
          result = {
            ...result,
            [label]: getCount(gt200Abfht.filter(item => item.akzeptanzgrund === akzeptanzgrund)),
          };
        });
        const weekOfYear =
          weekData[0] && weekData[0].hasOwnProperty('date') ? getWeek(new Date(weekData[0].date.start)) : '';
        result = {
          ...result,
          week: weekOfYear,
          xKey: 'KW' + weekOfYear.toString(),
          totalCount,
          weekStartDate: weekData[0]?.date?.start,
        };
        return result;
      })
      .sort((a, b) => compareDesc(new Date(b.weekStartDate), new Date(a.weekStartDate)));
    const yAxisMaxValue = formattedData.reduce(
      (acc, curr) => (acc > curr.totalCount ? acc : curr.totalCount),
      0
    );
    return formattedData.map(item => ({ ...item, yAxisMaxValue }));
  },
  getHistoricBearbeitungsData(state, getters) {
    const data = getters.getFilteredHistoricData;
    return data
      .map(weekData => {
        const alleZuBearbeitendenAbfhtCount = getCount(
          weekData.filter(item => !(item.status === 'Verfuegbar' && item.aufpreis <= 200))
        );
        const bearbeiteteAbfhtCount = getCount(
          weekData.filter(
            item => !(item.status === 'Verfuegbar' && item.aufpreis <= 200) && item.bearbeitungStatus !== null
          )
        );
        const akzeptierteAbfhtCount = getCount(
          weekData.filter(item => item.bearbeitungStatus === 'Akzeptiert')
        );
        const verfuegbarkeitGeschaffenAbfhtCount = getCount(
          weekData.filter(item => item.bearbeitungStatus === 'VerfuegbarkeitGeschaffen')
        );
        const verfuegbarkeitNichtGeschaffenAbfhtCount = getCount(
          weekData.filter(item => item.bearbeitungStatus === 'VerfuegbarkeitNichtGeschaffen')
        );
        const weekOfYear =
          weekData[0] && weekData[0].hasOwnProperty('date') ? getWeek(new Date(weekData[0].date.start)) : '';
        const nichtBearbeiteteAbfhtCount = alleZuBearbeitendenAbfhtCount - bearbeiteteAbfhtCount;
        return {
          Bearbeitet:
            akzeptierteAbfhtCount +
            verfuegbarkeitGeschaffenAbfhtCount +
            verfuegbarkeitNichtGeschaffenAbfhtCount,
          'Nicht bearbeitet': nichtBearbeiteteAbfhtCount,
          week: weekOfYear,
          xKey: 'KW' + weekOfYear.toString(),
          totalCount:
            akzeptierteAbfhtCount +
            verfuegbarkeitGeschaffenAbfhtCount +
            verfuegbarkeitNichtGeschaffenAbfhtCount +
            nichtBearbeiteteAbfhtCount,
          weekStartDate: weekData[0]?.date?.start,
        };
      })
      .sort((a, b) => compareDesc(new Date(b.weekStartDate), new Date(a.weekStartDate)));
  },
  getHistoricVerfuegbarkeitsData(state, getters) {
    const data = getters.getFilteredHistoricData;
    return data
      .map(weekData => {
        const weekOfYear =
          weekData[0] && weekData[0].hasOwnProperty('date') ? getWeek(new Date(weekData[0].date.start)) : '';
        const alleZuBearbeitendenAbfhtCount = getCount(
          weekData.filter(item => !(item.status === 'Verfuegbar' && item.aufpreis <= 200))
        );
        const bearbeiteteAbfhtCount = getCount(weekData.filter(item => item.bearbeitungStatus !== null));
        const akzeptierteAbfhtManuellCount = getCount(
          weekData.filter(item => item.bearbeitungStatus === 'Akzeptiert' && item.lastModifiedUserId !== null)
        );
        const akzeptierteAbfhtAutomatischCount = getCount(
          weekData.filter(item => item.bearbeitungStatus === 'Akzeptiert' && item.lastModifiedUserId === null)
        );
        const akzeptierteAbfhtCount = getCount(
          weekData.filter(item => item.bearbeitungStatus === 'Akzeptiert')
        );
        const verfuegbarkeitNichtGeschaffenAbfhtAutomatischCount = getCount(
          weekData.filter(
            item =>
              item.bearbeitungStatus === 'VerfuegbarkeitNichtGeschaffen' && item.lastModifiedUserId === null
          )
        );
        const verfuegbarkeitNichtGeschaffenAbfhtManuellCount = getCount(
          weekData.filter(
            item =>
              item.bearbeitungStatus === 'VerfuegbarkeitNichtGeschaffen' && item.lastModifiedUserId !== null
          )
        );
        const verfuegbarkeitNichtGeschaffenAbfhtCount = getCount(
          weekData.filter(item => item.bearbeitungStatus === 'VerfuegbarkeitNichtGeschaffen')
        );
        const verfuegbarkeitGeschaffenAbfhtManuellCount = getCount(
          weekData.filter(
            item => item.bearbeitungStatus === 'VerfuegbarkeitGeschaffen' && item.lastModifiedUserId !== null
          )
        );
        const verfuegbarkeitGeschaffenAbfhtAutomatischCount = getCount(
          weekData.filter(
            item => item.bearbeitungStatus === 'VerfuegbarkeitGeschaffen' && item.lastModifiedUserId === null
          )
        );
        const nichtBearbeiteteAbfhtCount = alleZuBearbeitendenAbfhtCount - bearbeiteteAbfhtCount;
        const keineVerfuegbarkeitGeschaffen = getCount(
          weekData.filter(item => item.bearbeitungStatus === 'VerfuegbarkeitNichtGeschaffen')
        );
        const verfuegbarkeitGeschaffenAbfhtCount = getCount(
          weekData.filter(item => item.bearbeitungStatus === 'VerfuegbarkeitGeschaffen')
        );
        const akzeptiert = getCount(weekData.filter(item => item.bearbeitungStatus === 'Akzeptiert'));

        return {
          'Verfügbarkeit geschaffen': verfuegbarkeitGeschaffenAbfhtCount,
          // 'Verfügbarkeit geschaffen (manuell)': verfuegbarkeitGeschaffenAbfhtAutomatischCount,
          'Keine Verfügbarkeit geschaffen': verfuegbarkeitNichtGeschaffenAbfhtCount,
          // 'Keine Verfügbarkeit geschaffen (manuell)': verfuegbarkeitNichtGeschaffenAbfhtManuellCount,
          Akzeptiert: akzeptierteAbfhtCount,
          //'Akzeptiert (manuell)': akzeptierteAbfhtManuellCount,
          week: weekOfYear,
          xKey: 'KW' + weekOfYear.toString(),
          totalCount:
            verfuegbarkeitNichtGeschaffenAbfhtAutomatischCount +
            verfuegbarkeitNichtGeschaffenAbfhtManuellCount +
            verfuegbarkeitGeschaffenAbfhtManuellCount +
            verfuegbarkeitGeschaffenAbfhtAutomatischCount +
            akzeptierteAbfhtManuellCount +
            akzeptierteAbfhtAutomatischCount,
          weekStartDate: weekData[0]?.date?.start,
        };
      })
      .sort((a, b) => compareDesc(new Date(b.weekStartDate), new Date(a.weekStartDate)));
  },
  getHistoricTopFiveUrsachen(state, getters) {
    const data = getters.getFilteredHistoricData;
    return data.map(weekData => {
      const allUrsachenCount = getCount(weekData.filter(item => item.ursache !== null));
      const getUrsachenCounts = allUrsachen
        .map(ursache => {
          const ursacheCount = getCount(weekData.filter(item => item.ursache === ursache.enum));
          const ursacheLabel = ursache.label;
          return {
            count: ursacheCount,
            ursache: ursacheLabel,
          };
        })
        .filter(item => item.count > 0)
        .sort((a, b) => b.count - a.count);

      let slicedUrsachenCounts;
      if (getUrsachenCounts.length > 5) {
        slicedUrsachenCounts = getUrsachenCounts.slice(0, 5);
      } else slicedUrsachenCounts = getUrsachenCounts;

      return {
        allCount: allUrsachenCount,
        data:
          Object.keys(slicedUrsachenCounts).length > 0
            ? slicedUrsachenCounts
                .map(item => ({ [item.ursache]: item.count }))
                .reduce(
                  (obj, item) => ({
                    ...obj,
                    [Object.keys(item)[0]]: item[Object.keys(item)[0]],
                  }),
                  {}
                )
            : {},
      };
    });
  },
  getHistoricVerfuegbarkeitGeschaffen(state, getters) {
    const data = getters.getFilteredHistoricData;
    // Als Verf. geschaffen zählt N auf RQ und RQ auf J<200, N auf J<200
  },
  getHistoricUrsachenData(state, getters) {
    const data = getters.getFilteredHistoricData;
    const ursachenData = data
      ?.map(weekData => {
        return weekData
          .filter(item => item.ursache !== null && item.bearbeitungStatus === 'VerfuegbarkeitGeschaffen')
          .reduce((acc, curr) => {
            const ursachenLabel = allUrsachen.find(item => item.enum === curr.ursache).label;
            if (acc.hasOwnProperty(ursachenLabel)) {
              acc[ursachenLabel] += curr.count;
            } else {
              acc[ursachenLabel] = curr.count;
            }
            return acc;
          }, {});
      })
      .map((item, i) => {
        const weekOfYear =
          data[i][0] && data[i][0].hasOwnProperty('date') ? getWeek(new Date(data[i][0].date.start)) : '';
        const totalCount = getCount(
          data[i].filter(
            item => item.ursache !== null && item.bearbeitungStatus === 'VerfuegbarkeitGeschaffen'
          )
        );
        return {
          ...item,
          week: weekOfYear,
          xKey: 'KW' + weekOfYear.toString(),
          totalCount,
          weekStartDate: data[i][0]?.date?.start,
        };
      })
      .sort((a, b) => compareDesc(new Date(b.weekStartDate), new Date(a.weekStartDate)));
    const yAxisMaxValue = ursachenData.reduce(
      (acc, curr) => (acc > curr.totalCount ? acc : curr.totalCount),
      0
    );
    return ursachenData.map(item => ({ ...item, yAxisMaxValue }));
  },
};

const actions = {
  [GET_HISTORIC_DATA](context) {
    context.commit(SET_IS_BUSY, true);

    const period = getLastWeeks(12);
    const req = date =>
      'Flugverfuegbarkeit/?$apply=filter(manuellAbgefragt eq false and abfragedatum ge ' +
      date.start +
      ' and abfragedatum le ' +
      date.end +
      ')/groupby((aufpreis,akzeptanzgrund,ursache,deutscherAbflughafenIataCode,status,reisetermin/reise/reisekuerzel,reisetermin/reise/region,reisetermin/reise/prioritaet,bearbeitungStatus,lastModifiedUserId),aggregate($count as count))';

    const allRequests = period.map(week => req(week));
    Promise.all(allRequests.map(request => apiService.get(request))).then(values => {
      const results = values.map(requestResponse => requestResponse.data.result.value);
      const formattedResponse = results.map((entry, i) => {
        return entry.map(item => {
          return {
            bearbeitungStatus: item.bearbeitungStatus,
            status: item.status,
            ursache: item.ursache,
            deutscherAbflughafen: item.deutscherAbflughafenIataCode,
            reisekuerzel: item.reisetermin.reise.reisekuerzel,
            prioritaet: item.reisetermin.reise.prioritaet,
            akzeptanzgrund: item.akzeptanzgrund,
            count: item.count,
            aufpreis: item.aufpreis,
            region: item.reisetermin.reise.region,
            date: period[i],
            lastModifiedUserId: item.lastModifiedUserId,
            week: 'weekMinus' + (i + 1),
          };
        });
      });

      context.commit(SET_HISTORIC_DATA, formattedResponse);
      context.commit(SET_IS_BUSY, false);
    });
  },
  [GET_DATA_WITH_ALL_GROUPINGS](context) {
    const lastSaturdayDate = calculateLastSaturday();
    if (context.state.filters.zeitraum.start === null && context.state.filters.zeitraum.end === null) {
      context.commit(SET_ZEITRAUM_FILTER, {
        start: lastSaturdayDate,
        end: format(add(new Date(lastSaturdayDate), { days: 7 }), 'yyyy-MM-dd'),
      });
    }
    const req =
      'Flugverfuegbarkeit/?$apply=filter(manuellAbgefragt eq false and abfragedatum ge ' +
      context.state.filters.zeitraum.start +
      ' and abfragedatum lt ' +
      context.state.filters.zeitraum.end +
      ')/groupby((aufpreis,akzeptanzgrund,ursache,deutscherAbflughafenIataCode,status,reisetermin/reise/reisekuerzel,reisetermin/reise/region,reisetermin/reise/prioritaet,bearbeitungStatus,lastModifiedUserId),aggregate($count as count))';

    context.commit(SET_IS_BUSY, true);
    apiService
      .get(req)
      .then(response => {
        const result = response.data.result.value;
        const formattedResponse = result
          .map(item => {
            return {
              bearbeitungStatus: item.bearbeitungStatus,
              status: item.status,
              ursache: item.ursache,
              deutscherAbflughafen: item.deutscherAbflughafenIataCode,
              reisekuerzel: item.reisetermin.reise.reisekuerzel,
              prioritaet: item.reisetermin.reise.prioritaet,
              akzeptanzgrund: item.akzeptanzgrund,
              count: item.count,
              aufpreis: item.aufpreis,
              region: item.reisetermin.reise.region,
              lastModifiedUserId: item.lastModifiedUserId,
            };
          })
          .flat();
        context.commit(SET_ALL_GROUPED_DATA, formattedResponse);
        context.commit(SET_FILTERED_DATA, formattedResponse);
      })
      .then(() => {
        context.commit(SET_IS_BUSY, false);
      });
  },
};

const mutations = {
  // data
  [SET_ALL_GROUPED_DATA](state, data) {
    state.data.allGroupedData = data;
  },
  [SET_FILTERED_DATA](state, data) {
    state.data.filteredData = data;
  },
  [SET_HISTORIC_DATA](state, historicData) {
    state.data.historicData = historicData;
  },

  // loading
  [SET_IS_BUSY](state, isBusy) {
    state.isBusy = isBusy;
  },

  // filters
  [SET_COUNTRY_FILTER](state, filter) {
    state.filters.country = filter;
  },
  [RESET_COUNTRY_FILTER](state) {
    state.filters.country = [];
  },
  [SET_REGION_FILTER](state, filter) {
    state.filters.region = filter;
  },
  [RESET_REGION_FILTER](state) {
    state.filters.region = [];
  },
  [SET_REISEN_FILTER](state, reisen) {
    state.filters.reisen = reisen;
  },
  [SET_DEUTSCHER_ABFLUGHAFEN_FILTER](state, deutscherAbflughafen) {
    state.filters.deutscherAbflughafen = deutscherAbflughafen;
  },
  [RESET_DEUTSCHER_ABFLUGHAFEN_FILTER](state) {
    state.filters.deutscherAbflughafen = [];
  },
  [SET_PRIORITAET_FILTER](state, prioritaet) {
    state.filters.prioritaet = prioritaet;
  },
  [RESET_PRIORITAET_FILTER](state) {
    state.filters.prioritaet = [];
  },
  [SET_REGIONSMANAGER_FILTER](state, regionsmanager) {
    state.filters.regionsmanager = regionsmanager;
  },
  [RESET_REGIONSMANAGER_FILTER](state) {
    state.filters.regionsmanager = [];
  },
  [SET_NUR_AKZEPTIERT_FILTER](state, nurAkzeptiert) {
    state.filters.nurAkzeptiert = nurAkzeptiert;
  },
  [SET_ZEITRAUM_FILTER](state, zeitraum) {
    state.filters.zeitraum = zeitraum || { start: null, end: null };
  },
  [RESET_ALL_FILTERS](state) {
    state.filters = {
      reisen: [],
      deutscherAbflughafen: [],
      prioritaet: [],
      region: [],
      country: [],
      zeitraum: state.filters.zeitraum,
      regionsmanager: [],
      nurAkzeptiert: false,
    };
  },
};

const getCount = array => array.reduce((acc, value) => acc + value?.count, 0);

const getLastWeeks = length => {
  const lastSaturday = calculateLastSaturday();
  const weeks = [];

  for (let i = 0; i < length; i++) {
    const start = format(sub(new Date(lastSaturday), { weeks: i }), 'yyyy-MM-dd');
    const end = format(sub(new Date(lastSaturday), { days: 1, weeks: i - 1 }), 'yyyy-MM-dd');
    weeks.push({ start, end });
  }

  return weeks;
};
export default {
  state,
  actions,
  getters,
  mutations,
};
